import React, { useState } from 'react';

const SpeechToText = ({ setUserInput }) => {
    // const [finalTranscript, setTranscript] = useState('');
    // const [tempTranscript, setTempTranscript] = useState('');
    const [recognition, setRecognition] = useState(null);

    // This function is called when the "Start Recording" button is clicked
    const startRecording = () => {
        // Check if the browser supports SpeechRecognition
        if (!('SpeechRecognition' in window || 'webkitSpeechRecognition' in window)) {
            alert('Your browser does not support speech recognition. Please try Google Chrome.');
            return;
        }

        // Create a new SpeechRecognition object
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        const recognition = new SpeechRecognition();

        // Set the recognition parameters
        recognition.interimResults = true;
        recognition.lang = 'en-US';

        // This event happens when you start talking
        recognition.onstart = () => {
            console.log('Start speaking...');
        };

        // This event happens when the speech recognition service returns a result
        recognition.onresult = (event) => {
            let interimTranscript = '';
            let tempTranscript = ''; // move the declaration outside of the loop

            for (let i = event.resultIndex; i < event.results.length; i++) {
                tempTranscript = event.results[i][0].transcript;
                if (event.results[i].isFinal) {
                    interimTranscript += tempTranscript + ' ';
                }
            }

            // only update the transcript when the result is final
            if (interimTranscript.trim() !== '') {
                // setTranscript(prevTranscript => prevTranscript + interimTranscript);

                setUserInput(prevInput => prevInput + interimTranscript);
                console.log("this is interimTranscript:", interimTranscript);
                // console.log('this is transcript:', finalTranscript);
                // setTranscript('');
                recognition.stop();
            }
        };

        // Start the speech recognition service
        recognition.start();

        setRecognition(recognition);
    };

    // // This function is called when the "Stop Recording" button is clicked
    // const stopRecording = () => {
    //     if (recognition) {
    //         recognition.stop();
    //     }
    // };


    return (
        <div>
            <button type="button" onClick={startRecording}>
                {/* <img src={microphoneIcon} alt="Microphone" className="youtube-icon" style={{ backgroundColor: 'transparent' }} /> */}
                Start Recording
            </button>
            {/* <button onClick={stopRecording}>Stop Recording</button> */}
            {/* <p>{finalTranscript}</p> */}
        </div>
    );
};

export default SpeechToText;
