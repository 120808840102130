import React from 'react';
import { Button } from '@mui/material';

const SidebarContent = ({ transcripts, handleTranscriptSelection, selectedTranscript, handleAddTranscriptClick }) => (
  <>
    {transcripts.map((transcript) => (
      <div
        key={transcript.id}
        onClick={() => handleTranscriptSelection(transcript.id)}
        className={`transcript-item${selectedTranscript === transcript.id ? " transcript-item-selected" : ""}`}
      >
        {transcript.title}
      </div>
    ))}

    <Button onClick={handleAddTranscriptClick}>+ Transcript</Button>
  </>
);

export default SidebarContent;