import React, { useState, useEffect } from 'react';
import './App.css';

import { auth } from "./firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import { SignInButton, SignOutButton } from './googleuserauthorization';
import { AppBar, Box, LinearProgress, IconButton, Button, TextField, Typography, Toolbar, Paper, Drawer, Container } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SendIcon from '@mui/icons-material/Send';
import LanguageIcon from '@mui/icons-material/Language';

import SpeechToText from './components/Microphone';
import SidebarContent from './components/SidebarContent';
import * as pdfjsLib from 'pdfjs-dist/webpack';
// pdfjsLib.GlobalWorkerOptions.workerSrc = require('pdfjs-dist/es5/build/pdf.worker.entry');
// ... rest of your code

import mammoth from 'mammoth';
{/* <><script src="https://mozilla.github.io/pdf.js/build/pdf.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/mammoth/1.4.2/mammoth.browser.min.js"></script></> */}


function App() {
  const [userInput, setUserInput] = useState('');
  const [loading, setLoading] = useState(false);
  // const [websiteURL, setWebsiteURL] = useState('');
  // const [youtubeURL, setyoutubeURL] = useState('');
  const [currentURL, setCurrentURL] = useState('');
  const [showUploadForms, setShowUploadForms] = useState(true);
  const [selectedTranscript, setSelectedTranscript] = useState(null);
  const [transcripts, setTranscripts] = useState([]);
  const [chatHistory, setChatHistory] = useState([]);

  const [sidebarOpen, setSidebarOpen] = React.useState(false);

  const [user, setUser] = useState(null);
  const [userEmail, setUserEmail] = useState(null);

  const [submitFlag, setSubmitFlag] = useState(false);

  const addToChatHistory = (type, text) => {
    setChatHistory(prevChatHistory => [...prevChatHistory, { type, text }]);
  };

  const updateLastChat = (type, text) => {
    setChatHistory(prevChatHistory => {
      console.log(prevChatHistory);
      const lastChat = prevChatHistory[-1];
      console.log(lastChat);
      return [...prevChatHistory.slice(0, -1), { ...prevChatHistory[-1], type: type, text: text }];
    });
  }


  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    // This function is run when the component mounts and whenever auth state changes
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setUserEmail(currentUser ? currentUser.email : null);

      if (!currentUser) {
        setTranscripts([]);
        setSelectedTranscript(null);
        setChatHistory([]);
      }
    });

    // Clean up subscription on unmount
    return () => {
      unsubscribe();
    };
  }, []); // Empty dependency array means this effect runs once on mount and unmount



  useEffect(() => {
    // Need to define the endpoint in the Flask API
    const fetchTranscripts = async () => {

      const res = await fetch('https://functions-qxderxkjla-uw.a.run.app/getTranscriptPerUser', {
        headers: new Headers({
          'userEmail': userEmail,  // replace 'X-User-Id' with the actual header name expected by the server
        }),
      });
      const data = await res.json();
      setTranscripts(data);
    };

    if (user) {
      fetchTranscripts();
    }
    setSubmitFlag(false)
  }, [submitFlag, user]);


  const handleTranscriptSelection = async (transcriptId) => {
    const res = await fetch('https://functions-qxderxkjla-uw.a.run.app/getChatHistory', {
      headers: new Headers({
        "userEmail": userEmail, "transcriptId": transcriptId,
      }),
    })
    const data = await res.json();

    setSelectedTranscript(transcriptId);
    setUserInput('');
    setChatHistory(data);
  };

  const handleAddTranscriptClick = () => {
    setSelectedTranscript('');
    if (!showUploadForms)
      setShowUploadForms(true); // Toggle the visibility
  };

  const uploadTranscript = async (event) => {
    // Prevent the form from being submitted normally
    event.preventDefault();

    // Get the text input element
    var transcriptInput = currentURL;

    // Do something with transcriptText...
    console.log(transcriptInput);

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ "userEmail": userEmail, "webUrl": transcriptInput, "chat_id": selectedTranscript }),
    };

    const res = await fetch('https://functions-qxderxkjla-uw.a.run.app/uploadTranscript', requestOptions);
    const data = await res.json();
    console.log(data);

    // Clear the input field
    setCurrentURL('');
    // Reaload the page and call UseEffect functions  
    setSubmitFlag(true);
  }

  const uploadYouTube = async (event) => {
    // Prevent the form from being submitted normally
    event.preventDefault();

    // console.log(currentURL);

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ "userEmail": userEmail, "webUrl": currentURL, "chat_id": selectedTranscript }),
    };

    const res = await fetch('https://functions-qxderxkjla-uw.a.run.app/uploadYoutube', requestOptions);
    const data = await res.json();
    console.log(data);

    // Clear the input field
    setCurrentURL('');
    // Reaload the page and call UseEffect functions  
    setSubmitFlag(true);
  }

  const uploadCurrentURL = async (event) => {
    event.preventDefault();
    console.log(currentURL);

    if (currentURL.includes("youtube.com/watch?v=")) {
      uploadYouTube(event);
    } else {
      uploadTranscript(event);
    }

  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    addToChatHistory('user', userInput); // add user's message to the chat history

    // Call the Flask API
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'userEmail': userEmail },
      body: JSON.stringify({ user_input: userInput, transcript_id: selectedTranscript }),
    };

    let bytes = 0;
    let chunks = 0;
    let finalResponse = "";

    try {
      const response = await fetch('https://functions-qxderxkjla-uw.a.run.app/chat', requestOptions);
      const reader = response.body.getReader();
      addToChatHistory('bot', "");

      while (true) {
        const { done, value } = await reader.read();

        if (done) break;

        bytes += value.length;
        const chunk = new TextDecoder().decode(value);
        finalResponse += chunk;
        console.log(`Chunk ${chunks}: ${chunk}. Read ${bytes} characters.`);
        updateLastChat('bot', finalResponse);
        chunks++;
      }
    } catch (e) {
      if (e instanceof TypeError) {
        console.log(e);
        console.log("TypeError: Browser may not support async iteration");
      } else {
        console.log(`Error in async iterator: ${e}.`);
      }
    }

    if (finalResponse.length > 0 && finalResponse.endsWith("}")) {
      updateLastChat('bot', finalResponse.substring(0, finalResponse.length - 1));
    }
    setUserInput('');

    setLoading(false);
    e.target.reset();
  };

  const handleFileUpload = async (event) => {
    var files = event.target.files;
    if (files.length === 0) {
      // No file selected, just return
      return;
    }
    var file = event.target.files[0];
    var fileExtension = file.name.split('.').pop().toLowerCase();

    // Using FileReader to read the file
    var reader = new FileReader();
    reader.onload = async function (evt) {
      if (fileExtension === 'pdf') {
        var typedArray = new Uint8Array(evt.target.result);

        // Using PDFJS to parse the file to text
        var pdf = await pdfjsLib.getDocument({ data: typedArray }).promise;
        var totalPages = pdf.numPages;

        // Getting text from each page and concatenating it
        var pagesText = [];
        for (var i = 0; i < totalPages; i++) {
          var page = await pdf.getPage(i + 1);
          var textContent = await page.getTextContent();
          var pageText = textContent.items.map(item => item.str).join(' ');
          pagesText.push(pageText);
        }
        var pdfText = pagesText.join('\n');

        // Now pdfText contains the text of the PDF. Do what you need with it.
        console.log(pdfText);

      } else if (fileExtension === 'doc' || fileExtension === 'docx') {
        var arrayBuffer = evt.target.result;

        // Using Mammoth to convert Word file to text
        var result = await mammoth.extractRawText({ arrayBuffer: arrayBuffer });
        var wordText = result.value;

        // Now wordText contains the text of the Word document. Do what you need with it.
        console.log(wordText);
      }
      var title = file.name.split('.')[0]

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "userEmail": userEmail, "fileText": pdfText || wordText, "fileTitle": title }),
      };

      const res = await fetch('https://functions-qxderxkjla-uw.a.run.app/uploadTranscriptFile', requestOptions);
      const data = await res.json();
      console.log(data);

      // console.log("title:", title)

    };

    reader.readAsArrayBuffer(file);
  }



  return (
    <Box className="App">
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toggleSidebar}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontWeight: 'bold' }}>
              Welcome to PodGPT{user ? ", " + user.displayName : null}!
            </Typography>
            {user ? <SignOutButton /> : <SignInButton />}
          </Toolbar>
        </AppBar>
      </Box>
      <Drawer anchor="left" open={sidebarOpen} onClose={toggleSidebar}>
        <SidebarContent
          transcripts={transcripts}
          handleTranscriptSelection={handleTranscriptSelection}
          selectedTranscript={selectedTranscript}
          handleAddTranscriptClick={handleAddTranscriptClick}
        />
      </Drawer>
      <Box className="main-content">
        {selectedTranscript ? (
          <>
            <Container
              className="chat-container"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                height: '86vh'
              }}
            >
              {loading ? (
                <Box>
                  <LinearProgress />
                </Box>
              ) : null}
              <Box
                className="chat-history"
                sx={{
                  overflowY: 'scroll',
                  flexGrow: 1,
                  padding: '20px',
                }}
              >
                {chatHistory.map((message, index) => (
                  <Paper
                    key={index}
                    variant="outlined"
                    sx={{
                      alignSelf: message.type === "bot" ? 'flex-start' : 'flex-end',
                      textAlign: message.type === "bot" ? 'left' : 'right',
                      background: message.type === "bot" ? '#e8eaf6' : '#e0f7fa',
                      borderRadius: '20px',
                      margin: '5px',
                      padding: '10px',
                    }}
                  >
                    {(message.type === "bot" ? "Chat: " : "You: ") + message.text}
                  </Paper>
                ))}
              </Box>
              <Box sx={{ marginTop: 'auto' }}>
                <form onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    type="text"
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    placeholder="Ask a question"
                    disabled={loading}
                  />
                  <SpeechToText setUserInput={setUserInput}></SpeechToText>
                  <IconButton type="submit" disabled={loading}>
                    <SendIcon />
                  </IconButton>
                </form>
              </Box>
            </Container>
          </>
        ) : (
          <>
            <Box sx={{ paddingY: 2 }}>
              <Typography variant="h5">Upload a URL with the article of your interest</Typography>
              <form onSubmit={uploadCurrentURL}>
                <IconButton>
                  <LanguageIcon />
                </IconButton>
                <img src="/youtube-icon.svg" alt="YouTube Icon" className="youtube-icon" />
                <Box sx={{ width: '50%' }}>
                  <TextField
                    fullWidth
                    type="text"
                    value={currentURL}
                    onChange={(e) => setCurrentURL(e.target.value)}
                    placeholder="URL"
                  />
                </Box>

                <Button type="submit">Upload</Button>
              </form>
            </Box>
            <Box sx={{ paddingY: 2 }}>
              <Typography variant="h5">Or upload a PDF/Word file</Typography>
              <form onSubmit={(e) => e.preventDefault()}> {/* Prevent form submission on file upload */}
                <input
                  type="file"
                  id="fileUpload"
                  accept=".pdf,.doc,.docx"
                  style={{ display: 'none' }} // Hide the file upload button
                  onChange={(e) => handleFileUpload(e)}
                />
                <label htmlFor="fileUpload">
                  <Button component="span">Upload File</Button> {/* Styled button for file upload */}
                </label>
              </form>
            </Box>

            {/* <Box sx={{ paddingY: 2 }}>
                <Typography variant="h5">Upload a URL to a YouTube video of your interest</Typography>
                <form onSubmit={uploadYouTube}>
                  <img src="/youtube-icon.svg" alt="YouTube Icon" className="youtube-icon" />
                  <Box sx={{ width: '50%' }}>
                    <TextField
                      fullWidth
                      type="text"
                      value={youtubeURL}
                      onChange={(e) => setyoutubeURL(e.target.value)}
                      placeholder="YouTube URL"
                    />
                  </Box>
                  <Button type="submit">Upload</Button>
                </form>
              </Box> */}
          </>
        )}
      </Box>
    </Box>
  );
}

export default App;
