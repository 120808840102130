import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCuTfVOf8gdLHySpcH-b0nLgOfZcz1mKLw",
    authDomain: "podgpt-caa2b.firebaseapp.com",
    projectId: "podgpt-caa2b",
    storageBucket: "podgpt-caa2b.appspot.com",
    messagingSenderId: "932100110585",
    appId: "1:932100110585:web:5f6de224bf9c1667ee0a28",
    measurementId: "G-NQCRHF5944"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);