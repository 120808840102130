import React from 'react';
import { auth } from "./firebaseConfig";
import { GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";

export const SignInButton = () => {
    const signInWithGoogle = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider);
    };

    return (
        <button onClick={signInWithGoogle}>Sign in with Google!!</button>
    );
}

export const SignOutButton = () => {
    const signOutFromGoogle = () => {
        signOut(auth)
            .then(() => {
                console.log("Signed out successfully");
            })
            .catch((error) => {
                console.error("Error signing out", error);
            });
    };

    return (
        <button onClick={signOutFromGoogle}>Sign Out</button>
    );
}


